import axios from '@/utils/axios.js';

//上传文件
const PICTURE_MAX_SIZE = 1024 * 512;//图片最大大小->0.5MB=524288B
export function uploadFiles(files) {
    //图片压缩
    function compressPicture(file){
        return new Promise(function (resolve) {
            let cal = PICTURE_MAX_SIZE / file.file.size; //计算比例，也可以自己定义比例 0.5等等
            let reader = new FileReader();
            reader.readAsDataURL(file.file);
            reader.onload = function () {
                let image = new Image(); //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
                image.src = file.content; //file是我用vant里的组件，里有转好的base64格式content
                image.onload = function () {    //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
                    let canvas = document.createElement('canvas'), //创建一个canvas元素
                        context = canvas.getContext('2d'),    //context相当于画笔，里面有各种可以进行绘图的API
                        imageWidth = image.width * cal,    //压缩后图片的宽度
                        imageHeight = image.height * cal    //压缩后图片的高度
                    canvas.width = imageWidth    //设置绘图的宽度
                    canvas.height = imageHeight    //设置绘图的高度
                    //使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
                    context.drawImage(image, 0, 0, imageWidth, imageHeight)
                    //使用toDataURL将canvas上的图片转换为base64格式
                    resolve(canvas.toDataURL('image/jpeg'));
                }
            }
        });
    }

    //接口入参转换
    async function getPostData(files){
        async function convertData(file){
            if (file.file.size > PICTURE_MAX_SIZE) {//判断是否大于图片最大大小
                return {
                    name: file.file.name,
                    content: await compressPicture(file)
                };
            }else{
                postData.push({
                    name: file.file.name,
                    content: file.content
                });
            }
        }

        let postData = [];
        if(Array.isArray(files)){
            for(let file of files){
                postData.push(await convertData(file));
            }
        }else{
            postData.push(await convertData(files));
        }
        return postData;
    }

    //调用图片上传接口
    return new Promise(function (resolve) {
        getPostData(files).then((postData)=>{
            resolve(axios.post('/api/User/UploadBase64', postData));
        },()=>{});
    });
}

//获取科室
export function getDepartment(applyType) {
    return axios.get('/api/OutPatient/GetRegisterDepartment?applyType=' + applyType);
}

//获取科室医生
export function getDoctors(departmentId, applyType) {
    return axios.get('/api/OutPatient/GetDoctorList?departmentid=' + departmentId + '&applyType=' + applyType);
}
//搜索医生姓名
export function searchDoctors(doctorName, applyType) {
    return axios.get('/api/OutPatient/SearchDoctor?name=' + doctorName + '&applyType=' + applyType);
}
//获取医生简介
export function getDoctorInfo(jobnumber, departmentid, applyType) {
    return axios.get('/api/OutPatient/GetDoctorInfo?jobnumber=' + jobnumber + '&departmentid=' + departmentid + '&applyType=' + applyType);
}


//校验是否重复提交
export function checkApplyRepeat(appointTime, cardID, depID, oldDdbm, applyType) {
    return axios.get('/api/OutPatient/CheckDateTime?appointTime=' + appointTime + '&cardID=' + cardID + '&depID=' + depID + '&old_ddbm=' + oldDdbm + '&applyType=' + applyType);
}
//提交咨询申请单
export function commitApplyBill(postData) {
    return axios.post('/api/OutPatient/CommitApplyInfo', postData);
}